<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-stepper v-model="step" alt-label class="mt-3 mb-3" v-if="!registrationComplete">
            <v-toolbar dark dense color="rfaccent2">
                <v-toolbar-title>
                    <v-icon>mdi-account-plus</v-icon>
                    Register a New Account
                </v-toolbar-title>
            </v-toolbar>

            <v-stepper-header :class="$vuetify.theme.dark ? 'grey darken-4 elevation-5' : 'elevation-5'">
                <v-stepper-step :complete="step > 1" step="1" color="primary">Verify GMRS License</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2" color="purple">Account Information</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" color="green">Confirm Registration</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">

                    <v-card
                        class="mb-12 elevation-0"
                        color="transparent"
                    >
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-row>
                                    <v-col cols="12" md="6" class="mx-auto">
                                        <p class="text-center">Please enter your GMRS callsign as it appears on your
                                            license from the FCC.</p>
                                        <v-form v-model="formValidCallsign" ref="callsignForm"
                                                @submit.prevent="nextAccountInfo">
                                            <v-text-field
                                                v-model="callsign"
                                                label="GMRS Callsign"
                                                prepend-inner-icon="mdi-card-account-details-star"
                                                :rules="[rules.required, rules.callsignGMRS, callsignValid, callsignUnregistered]"
                                                @input="callsign = $_.isString(callsign) ? callsign.toUpperCase() : null"
                                                :loading="licenseLoading"
                                                :color="formValidCallsign ? 'success' : 'error'"
                                                autocomplete="off"
                                                maxlength="7"
                                                outlined
                                            ></v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pr-4">
                                        <v-alert type="info">A valid GMRS callsign is required to register for
                                            myGMRS.com. A license can be obtained from the FCC and does not require a
                                            test of any kind. A GMRS license covers you and your immediate family
                                            members for a period of 10 years.
                                            <router-link to="help/get-gmrs-license" class="white--text ml-3">Learn
                                                More
                                            </router-link>
                                        </v-alert>

                                        <!--                                        <v-alert type="info" border="left" colored-border prominent-->
                                        <!--                                                 class="elevation-4">-->
                                        <!--                                            <h4 class="title text-uppercase font-weight-light">FCC License Required</h4>-->
                                        <!--                                            <p>A valid GMRS callsign is required to register for myGMRS.com. A license-->
                                        <!--                                                can be obtained from the FCC and does not require a test of any kind. A-->
                                        <!--                                                GMRS license covers you and your immediate family members for a period-->
                                        <!--                                                of 10 years.-->
                                        <!--                                                <router-link to="help/get-gmrs-license">Learn More</router-link>-->
                                        <!--                                            </p>-->
                                        <!--                                            <p class="mb-0 font-weight-medium">Note: An Amateur Radio callsign is not-->
                                        <!--                                                valid on GMRS frequencies.</p>-->
                                        <!--                                        </v-alert>-->
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <License :key="callsign" :callsign="callsign" v-if="callsign && callsign.length === 7"/>
                                <div v-if="!formValidCallsign" class="text-center">
                                    <h3 class="font-weight-regular text-uppercase">Don't have a callsign?</h3>
                                    <v-btn class="my-1 white--text" large color="purple"
                                           to="/help/get-gmrs-license">
                                        <v-icon class="mr-1">mdi-certificate-outline</v-icon>
                                        Get a GMRS license
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-container>
                        <v-row class="mx-0">
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                @click="nextAccountInfo"
                                x-large
                                :disabled="!formValidCallsign"
                            >
                                Continue
                                <v-icon class="ml-2">mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-form v-model="formValidAccount" ref="accountForm" @submit.prevent="nextConfirmRegistration">
                        <v-card
                            class="mb-12 elevation-0"
                            color="transparent"
                        >
                            <v-row justify="center">
                                <v-col cols="12" md="6">
                                    <p class="mb-10">Please enter the information below to create your account. All
                                        fields are
                                        required.</p>

                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-text-field
                                                v-model="email"
                                                label="Email Address"
                                                :rules="rules.emailRules.concat(emailAvailableRule)"
                                                maxlength="254"
                                                @change="checkEmailUnregistered"
                                                :loading="checkEmailLoading"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="9" class="py-0">
                                            <v-text-field
                                                v-model="username"
                                                label="Username"
                                                :rules="rules.usernameRules.concat(usernameAvailableRule)"
                                                :counter="30"
                                                maxlength="30"
                                                @change="checkUsernameUnregistered"
                                                :loading="checkUserLoading"
                                                outlined
                                                hint="Your username can be anything you like as long as it's not offensive, or you can use your GMRS callsign."
                                                :disabled="useCallsign"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="py-0">
                                            <v-checkbox
                                                v-model="useCallsign"
                                                label="Use Callsign"
                                                :class="$vuetify.breakpoint.xsOnly ? 'mt-0' : ''"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-text-field
                                                v-model="password"
                                                label="Password"
                                                type="password"
                                                :rules="rules.passwordRules"
                                                :counter="64"
                                                maxlength="64"
                                                autocomplete="new-password"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="py-0">
                                            <v-text-field
                                                v-model="passwordConfirm"
                                                label="Password (Confirm)"
                                                type="password"
                                                :rules="rules.passwordRules.concat(passwordConfirmationRule)"
                                                maxlength="64"
                                                autocomplete="new-password"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-form>

                    <v-container>
                        <v-row class="mx-0">
                            <v-btn
                                text
                                @click="step = 1"
                            >Back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                @click="nextConfirmRegistration"
                                x-large
                                :disabled="!formValidAccount"
                            >
                                Continue
                                <v-icon class="ml-2">mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card
                        class="mb-12 elevation-0"
                        color="transparent"
                    >

                        <p class="headline font-weight-light text-center">Please confirm your account information and
                            then click the COMPLETE REGISTRATION button to create your account.</p>

                        <v-row justify="center">
                            <v-col cols="11" sm="8" lg="6" xl="4">
                                <v-card elevation="10">
                                    <v-simple-table dark class="rfaccent2">
                                        <tbody>
                                        <tr>
                                            <th class="subtitle-2" width="150">GMRS Callsign</th>
                                            <td class="subtitle-1">{{ callsign }}</td>
                                        </tr>
                                        <tr>
                                            <th class="subtitle-2" width="150">Username</th>
                                            <td class="subtitle-1">{{ username }}</td>
                                        </tr>
                                        <tr>
                                            <th class="subtitle-2" width="150">Email Address</th>
                                            <td class="subtitle-1">{{ email }}</td>
                                        </tr>
                                        <!--                                        <tr v-if="license">-->
                                        <!--                                            <th class="subtitle-2" width="150">Name</th>-->
                                        <!--                                            <td class="subtitle-1">{{ license.firstName }} {{ license.mi }} {{-->
                                        <!--                                                license.lastName }}-->
                                        <!--                                            </td>-->
                                        <!--                                        </tr>-->
                                        <!--                                        <tr v-if="license">-->
                                        <!--                                            <th class="subtitle-2" width="150">Address</th>-->
                                        <!--                                            <td class="subtitle-1">{{ license.streetAddress }}, {{ license.city }}, {{-->
                                        <!--                                                license.state }}-->
                                        <!--                                            </td>-->
                                        <!--                                        </tr>-->
                                        </tbody>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-container>
                        <v-row class="mx-0">
                            <v-btn
                                text
                                @click="step = 2"
                            >Back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="success"
                                @click="createAccount"
                                x-large
                                :loading="createAccountLoading"
                                :disabled="createAccountLoading"
                            >
                                Complete Registration
                                <v-icon class="ml-2">mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <div v-if="registrationComplete">
            <div class="stack-icon my-5">
                <v-icon
                    color="almostblack"
                    size="120"
                    class="stack-icon-bottom"
                >
                    mdi-circle
                </v-icon>

                <v-icon
                    size="150"
                    color="yellow darken-2"
                    class="stack-icon-top"
                >
                    mdi-emoticon-excited
                </v-icon>
            </div>

            <p class="display-1 font-weight-light text-center">
                Thank you for creating an account at myGMRS.com!
            </p>

            <!--            <p class="headline font-weight-light text-center">-->
            <!--                <v-icon color="warning">mdi-alert</v-icon>-->
            <!--                Check your email for an account confirmation message and follow the instructions on-->
            <!--                how to confirm your email address.-->
            <!--            </p>-->

            <v-row justify="center">
                <v-col cols="12" sm="12" md="10" xl="8">
                    <v-alert type="info" prominent border="left" elevation="10">
                        <span class="headline font-weight-light">Check your email for an account confirmation message and follow the instructions on
                        how to confirm your email address.</span>
                    </v-alert>
                </v-col>
            </v-row>
        </div>

        <v-dialog
            v-model="dialogs.alert.state"
            width="500"
        >
            <v-system-bar :color="dialogs.alert.type">
                <v-spacer></v-spacer>
                <v-icon size="18" @click="dialogs.alert.state = false">mdi-close</v-icon>
            </v-system-bar>
            <v-alert
                :type="dialogs.alert.type"
                class="mb-0 pt-0"
                prominent
                tile
            >

                {{ dialogs.alert.message }}
            </v-alert>
        </v-dialog>
    </v-container>
</template>

<style>
    .v-application--is-ltr .v-stepper__label {
        text-align: center !important;
    }

    .stack-icon {
        display: flex;
    }

    .stack-icon-top {
        width: 100%;
        margin-left: -100%;
    }

    .stack-icon-bottom {
        width: 100%;
        padding-top: 1px;
    }
</style>

<script>
    import axios from 'axios';
    import config from '../config';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import License from '@/components/License.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        name: 'Register',

        props: ['user'],

        components: {AdBTWR, License},

        data: () => ({
            rules: {
                required: value => !!value || 'Required',
                callsignGMRS: value => {
                    const regexOld = /^[A-Z]{3}\d{4}$/i;
                    const regexNew = /^[A-Z]{4}\d{3}$/i;
                    return regexNew.test(value) || regexOld.test(value) || (_.isString(value) && value.length === 7 ? 'Invalid callsign format' : 'Callsign too short')
                },
                usernameRules: [
                    v => !!v || 'Username is required',
                    v => (v && v.length <= 30) || 'Username must be less than 30 characters',
                    v => (v && v.length >= 3) || 'Username must be at least 3 characters',
                    v => {
                        if (!v) return false;
                        let code, i, len;
                        for (i = 0, len = v.length; i < len; i++) {
                            code = v.charCodeAt(i);
                            if (!(code > 47 && code < 58) && // numeric (0-9)
                                !(code > 64 && code < 91) && // upper alpha (A-Z)
                                !(code > 96 && code < 123)) { // lower alpha (a-z)
                                return 'Username must only contain letters and numbers';
                            }
                        }
                        return true;
                    }
                ],
                emailRules: [
                    v => !!v || 'Email is required',
                    v => /.+@.+\..+/.test(v) || 'Email must be valid',
                ],
                passwordRules: [
                    v => !!v || 'Password is required',
                    v => (v && v.length >= 8) || 'Must be at least 8 characters',
                    v => (v && v.length <= 64) || 'Must be no greater than 64 characters'
                ]
            },
            dialogs: {
                alert: {
                    state: false,
                    type: 'info',
                    message: ''
                }
            },
            registrationComplete: false,
            formValidCallsign: false,
            formValidAccount: false,
            licenseLoading: false,
            checkEmailLoading: false,
            checkUserLoading: false,
            license: null,
            step: 1,
            callsign: null,
            username: null,
            useCallsign: false,
            email: null,
            password: null,
            passwordConfirm: null,
            emailAvailable: true,
            usernameAvailable: true,
            createAccountLoading: false
        }),
        watch: {
            callsign() {
                if (this.callsign && this.callsign.length === 7) this.fetchLicense(this.callsign);
            },

            useCallsign() {
                if (this.useCallsign) {
                    this.username = this.callsign;
                    this.checkUsernameUnregistered();
                }
            }
        },

        computed: {
            passwordConfirmationRule() {
                return () => (this.password === this.passwordConfirm) || 'Password must match'
            },

            emailAvailableRule() {
                return () => this.emailAvailable || 'Email is already registered'
            },

            usernameAvailableRule() {
                return () => this.usernameAvailable || 'Username is already registered'
            }
        },

        methods: {
            createAccount() {
                const formData = {
                    Username: this.username.trim(),
                    Password: this.password,
                    Email: this.email.trim(),
                    Callsign: this.callsign.trim(),
                    First_Name: this.license ? this.license.firstName : null,
                    Last_Name: this.license ? this.license.lastName : null,
                    Address: this.license ? (this.license.poBox ? this.license.streetAddress + ' PO Box ' + this.license.poBox : this.license.streetAddress) : null,
                    City: this.license ? this.license.city : null,
                    State: this.license ? this.license.state : null,
                    // Requests: 1,
                    // Digest: 1,
                };

                this.createAccountLoading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .post(config.API_LOCATION + '/user', formData, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.registrationComplete = true;
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                        console.error(err);

                        this.dialogs.alert.message = err.response.data.message || 'An error has occurred while submitting your registration. Please try again later.';
                        this.dialogs.alert.type = 'error';
                        this.dialogs.alert.state = true;
                    })
                    .then(() => {
                        this.createAccountLoading = false;
                    });
            },

            nextAccountInfo() {
                if (this.formValidCallsign) {
                    this.step = 2;
                } else {
                    this.dialogs.alert.type = 'error';
                    this.dialogs.alert.message = 'Please enter a valid GMRS callsign.';
                    this.dialogs.alert.state = true;
                }
            },

            nextConfirmRegistration() {
                if (this.formValidAccount) {
                    this.step = 3;
                } else {
                    this.dialogs.alert.type = 'error';
                    this.dialogs.alert.message = 'Please enter valid account information to continue. All fields are required.';
                    this.dialogs.alert.state = true;
                }
            },

            fetchLicense(callsign) {
                this.licenseLoading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/license/' + callsign, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.license = response.data;
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }

                        this.license = null;
                    })
                    .then(() => {
                        this.licenseLoading = false;

                        this.$refs.callsignForm.validate();
                    });
            },

            lookupLicenseStatus(code) {
                switch (code) {
                    case 'A':
                        return 'Active';
                    case 'E':
                        return 'Expired';
                    case 'C':
                        return 'Cancelled';
                    case 'T':
                        return 'Terminated';
                    default:
                        return 'Unknown';
                }
            },

            callsignValid() {
                return this.license && this.license.licenseStatus === 'A' || `Callsign is ${this.license ? this.lookupLicenseStatus(this.license.licenseStatus) : 'invalid'}`;
            },

            callsignUnregistered() {
                return !this.license || !this.license.user || `Callsign is already registered to ${this.license.user}`;
            },

            checkEmailUnregistered() {
                this.checkEmailLoading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/check-email/' + this.email, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.emailAvailable = response.data.emailAvailable;
                        // if (!this.emailAvailable) {
                        // this.formValidAccount = false;
                        // }
                    })
                    .catch(err => {
                        if (err.response) {
                            switch (err.response.status) {
                                case 401:
                                    this.$emit('unauthorized');
                                    break;
                                case 404:
                                    this.emailAvailable = true;
                                    break;
                                default:
                                    //not clear, could be anything
                                    this.emailAvailable = false;
                                    // this.formValidAccount = false;
                                    break;
                            }
                        }
                    })
                    .then(() => {
                        this.checkEmailLoading = false;
                        this.$refs.accountForm.validate();
                    });
            },

            checkUsernameUnregistered() {
                this.checkUserLoading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/user/' + this.username, {
                        headers: axiosHeaders
                    })
                    .then(() => {
                        this.usernameAvailable = false;
                        // this.formValidAccount = false;
                    })
                    .catch(err => {
                        if (err.response) {
                            switch (err.response.status) {
                                case 401:
                                    this.$emit('unauthorized');
                                    break;
                                case 404:
                                    this.usernameAvailable = true;
                                    break;
                                default:
                                    //not clear, could be anything
                                    this.usernameAvailable = false;
                                    // this.formValidAccount = false;
                                    break;
                            }
                        }
                    })
                    .then(() => {
                        this.checkUserLoading = false;

                        this.$refs.accountForm.validate();
                    });
            },
        },

        mounted() {
            useTitle('myGMRS.com - Create Account');
        }
    }
</script>
